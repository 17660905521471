.keyImg{
    height: 100%;
    width: 100%;
    border-radius: 20px;
}
.key-heading{
    font-size: 35px;
    font-weight: bolder;
}
.key-paragraph{
    font-size: 22px;
}