.footer-bg {
  background-color: #5271ff;
}
.footer-list{
  color: white;
}
.footer-heading {
  font-size: 30px;
  color: white;
  font-weight: bolder ;
}
.order-list .footer-list {
  list-style: none;
  line-height: 30px;
  font-weight: 300;
}
.order-list a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.copyright {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: rgba(29, 29, 27, 1);
  color: white;
  text-align: center;
}
.copyright .footer-link {
  color: white;
}
@media screen and (max-width: 425px) {
  .copyright p{
    font-size: 14px;
  }
}
