body {
  font-family: "Roboto Condensed", sans-serif;
  overflow-x: hidden;
}
ul li .nav-link {
  font-size: 18px;
  font-weight: bold;
  color: rgb(155, 149, 149);
  text-align: center;
}

.nav-item {
  position: relative;
  color: rgb(192, 186, 186);
}

.nav-item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.nav-item:hover::before {
  background-color: #5271ff;
}
.dropbtn {
  background-color: #5271ff;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: rgb(155, 149, 149);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #5271ff !important;
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar-ctn {
  background-color: #5271ff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 40px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .navbar-ctn {
    display: none;
  }
  .animated-button {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .dropdown-content {
    position: relative;
    display: block;
    border: none;
    background-color: white;
    min-width: 160px;
    box-shadow: none;
    font-size: 18px;
    font-weight: bold;
    color: rgb(155, 149, 149);
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .navbar-ctn {
    display: none;
  }
  .animated-button {
    display: none;
  }
  .dropdown-content {
    position: relative;
    display: block;
    border: none;
    background-color: white;
    min-width: 160px;
    box-shadow: none;
    font-size: 18px;
    font-weight: bold;
    color: rgb(155, 149, 149);
    text-align: center;
  }
}
