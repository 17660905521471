.feature-bg-gray {
  background-color: lightgrey;
}
.card-title {
  font-size: 27px;
  padding-top: 10px;
  font-weight: 500;
  text-align: center;
}
.feature-text {
  text-align: center;
  color: black;
  font-family: "Roboto", Sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
}
.row .shadow {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-width: 70%;
}

.anchor {
  /* display: block; */
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  color: black;
}
.anchor:hover {
  color: black;
}

@media screen and (max-width: 768px) {
  .card-title {
    font-size: 15px;
    padding-top: 6px;
  }
  .card-subtitle {
    font-size: 12px;
  }
  .card {
    margin-top: 15px;
  }
}
