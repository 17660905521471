.contact-wrapper .card {
  background-color: rgba(29, 29, 27, 1);
  padding: 10% 5% 10% 5%;
}
.contact-wrapper .card-title {
  font-size: 30px;
}
.contact-wrapper .card-subtitle {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.9px;
  color: rgba(124, 124, 124, 1);
  line-height: 35px;
}
.contact-wrapper .card-subtitle a {
  box-shadow: none;
  text-decoration: none;
  color: rgba(124, 124, 124, 1);
}
.contact-wrapper .card-text {
  text-align: center;
  color: #ffffff;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1.9px;
}

.wrapper-form .card-title {
  text-align: start;
  color: rgba(29, 29, 27, 1);
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 32px;
  letter-spacing: 1.5px;
}
.wrapper-form .card-subtitle {
  color: rgba(124, 124, 124, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.wrapper-form input {
  width: 100%;
  padding: 5px 14px;
  max-width: 100%;
  background-color: #ffffff;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
  border: 1px solid #818a91;
}
.contact-btn {
  margin-top: 15px;
  background-color: #5271ff;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-radius: 50px 50px 50px 50px;
  font-size: 15px;
  padding: 12px 60px;
  text-align: center;
  transition: all 0.3s;
}

/* .contact-btn:hover{
  border: 1px solid #5271ff;
  background-color: white;
  color: #5271ff;
} */
