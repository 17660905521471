.bg-image {
  background-image: url("../Assets/Images/Aboutbg.jpg");
  height: 300px;
  background-position: center;
  background-size: cover;
}
.aboutbg-overlay {
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: #5271ff;
  opacity: 0.5;
  left: 0;
}
.service-bg-image {
  background-image: url("../Assets/Images/security-service.avif");
  height: 300px;
  background-position: center;
  background-size: cover;
}
.about-bg-text {
  position: relative;
  color: white;
  margin: 75px;
}
.about-bg-text .main {
  font-size: 45px;
  font-weight: bolder;
}
.about-text {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .about-bg-text .main {
    font-size: 35px;
  }
  .about-text {
    font-size: 17px;
  }
}
@media screen and (max-width: 425px) {
  .about-bg-text .main {
    font-size: 23px;
  }
  .about-text {
    font-size: 12px;
  }
}
