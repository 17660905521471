.home-content {
  color: black;
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
}
a .about-btn {
  font-weight: 700;
  padding: 15px 42px 15px 42px;
  background-color: #5271ff;
  color: white;
  border: none;
  border-radius: 50px;
}
@media screen and (max-width: 768px) {
  .home-content {
    font-size: 19px;
    line-height: 25px;
  }
  a .about-btn {
    font-weight: 500;
    padding: 10px 35px 10px 35px;
  }
}
